@import "~bootstrap/scss/functions";

/** FONTS **/
$font-source-sans: 'Source Sans Pro', sans-serif;
$font-source-code: 'Source Code Pro', sans-serif;
$font-istok-web: 'Istok Web', sans-serif;
$font-rubik: 'Rubik', sans-serif;

/** HEADER **/
$header-height: 70px;
$header-top: 20px;
$light-border: 1px solid #E0E0E0;

/** FOOTER **/
$footer-height: 170px;
$footer-bg: #1E2732;

/** COLORS **/
$foreground-light: #DBE4EE;
$background-grey: #364455;

$blue: #187DC7;
$hover-blue: #69C0FF;
$green: #18c467;
//$yellow: #d6db4a;
$red: #eb0000;
$orange: #FC7D58;

$light: #F7F7F7;
$light-blue: #f7fafe;
$gray-600: #6D757E;
$gray-500: #8e8e8e;
$hover-gray: #F2F2F2;
$dark: #242424;

$body-color: $dark;

$blue-gradient: linear-gradient(180deg, #2B96E3 0%, $blue 100%);
$green-gradient: linear-gradient(45deg, #18C467 0%, #187DC7 100%);

/** BOOTSTRAP **/

$border-radius:               4px;
$border-radius-lg:            4px;
$border-radius-sm:            4px;
$border-width:                1px;
$border-color:                adjust_color($dark, $alpha: -0.9);

$input-border-radius:         3px;
$input-border-radius-lg:      4px;
$input-border-radius-sm:      3px;

$link-hover-color:            #187DC7;
$link-decoration:             none;
$link-hover-decoration:       underline;

$box-shadow:                  0px 10px 10px -10px rgba(0, 0, 0, 0.25);

// Grid

$grid-columns:      12;
$grid-gutter-width: 20px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 375px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1024px,
  // Extra large screen / wide desktop
  xl: 1440px
);

$container-max-widths: (
  sm: 335px + $grid-gutter-width,
  md: 720px + $grid-gutter-width,
  lg: 992px + $grid-gutter-width,
  xl: 1240px + $grid-gutter-width
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      $font-rubik;

$font-weight-light:           200;
$font-weight-normal:          300;
$font-weight-bold:            500;
$font-weight-bolder:          700;
$headings-margin-bottom:      1em / 2;

$h1-font-size:                2.3rem; // 40px
$h2-font-size:                1.765rem; // 30px
$h3-font-size:                1.412rem; // 24px
$h4-font-size:                1.176rem; // 20px
$h5-font-size:                1rem;     // 17px

$small-font-size:             0.824rem; // 14px

$headings-color:              $dark;

$hr-margin-y:                 50px;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-bg:                    white;
$table-accent-bg:             $light;
$table-hover-bg:              #E4EBF5 !default;

$table-border-width:          1px;
$table-border-color:          white;

$table-head-bg:               $light;
$table-head-color:            $gray-600;

$table-striped-order:         even !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-font-family:       $font-rubik;

$btn-padding-y:               0.7rem;
$btn-padding-x:               1.058rem;

$btn-font-size:               $h4-font-size;
$btn-line-height:             1.4;

$btn-border-width:            2px;

$btn-padding-y-lg:            0.705rem;
$btn-padding-x-lg:            1.764rem;
$btn-font-size-lg:            1.176rem;
$btn-line-height-lg:          1.647rem;

$btn-border-radius-sm:        4px;

$btn-focus-width:             0;
$btn-border-width:            0;

$input-btn-focus-box-shadow:  0;
$btn-box-shadow:              0;
$btn-active-box-shadow:       0;
$btn-focus-box-shadow:        0;

$input-disabled-bg:           #F4F7FB;

// Forms

$label-margin-bottom:                   0.142rem;

$input-padding-y:                       0.735rem;
$input-padding-x:                       1rem;
$input-font-size:                       1rem;
$input-line-height:                     1.428;

$input-disabled-bg:                     white;

$input-border-color:                    #DBE4EE;
$input-border-width:                    1px;
$input-box-shadow:                      0;

$input-placeholder-color:               #6D757E;
$input-focus-border-color:              #C3DAF6;

$input-group-addon-bg:                  white;

$form-feedback-icon-invalid:            none;
$form-feedback-icon-valid:              none;

$custom-control-indicator-size:         1.428rem;

$custom-control-indicator-border-color: #DBE4EE;
$custom-control-indicator-checked-border-color: $blue;

$custom-control-indicator-checked-color: $blue;
$custom-control-indicator-checked-bg:   white;

$custom-range-track-height:             9px;
$custom-range-track-bg:                 adjust_color($dark, $alpha: -0.9);
$custom-range-track-border-radius:      3px;

$custom-range-thumb-width:              52px;
$custom-range-thumb-height:             52px;
$custom-range-thumb-active-bg:          darken(white, 10%);
$custom-range-thumb-border-radius:      50%;
$custom-range-thumb-border:             none;
$custom-range-thumb-box-shadow:         0px 10px 14px rgba(24, 125, 199, 0.04), 0px 3px 0px rgba(0, 71, 154, 0.04);

// Modals

$modal-inner-padding:               0 5rem 3.571rem;
$modal-content-border-width:        0;
$modal-header-border-width:         0;
$modal-header-padding-y:            2.142rem;
$modal-header-padding-x:            2.142rem;

$modal-content-bg:                  $light;

$modal-backdrop-bg:                 $light;
$modal-backdrop-opacity:            1;

// Modals

// Padding applied to the modal body
$modal-inner-padding:               0 5rem 3.571rem;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

// Cards

$card-spacer-y:                     1rem;
$card-spacer-x:                     1.642rem;
$card-border-color:                 #DBE4EE !default;
$card-cap-bg:                       $light;


// Dropdowns

$dropdown-border-width:             0;
$dropdown-border-radius:            4px;

$dropdown-padding-y:                0;

$dropdown-link-color:               $dark;
$dropdown-link-hover-color:         $dark;
$dropdown-link-hover-bg:            transparent;

$dropdown-item-padding-y:           .588rem !default;
$dropdown-item-padding-x:           1rem !default;

// Pagination

$pagination-padding-y:              .64rem;
$pagination-padding-x:              1rem;
//$pagination-padding-y-sm:           .25rem !default;
//$pagination-padding-x-sm:           .5rem !default;
//$pagination-padding-y-lg:           .75rem !default;
//$pagination-padding-x-lg:           1.5rem !default;
//$pagination-line-height:            1.25 !default;
//
$pagination-color:                  $gray-500;
//$pagination-bg:                     $white !default;
//$pagination-border-width:           $border-width !default;
//$pagination-border-color:           $gray-300 !default;
//
//$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
//$pagination-focus-outline:          0 !default;
//
//$pagination-hover-color:            $link-hover-color !default;
//$pagination-hover-bg:               $gray-200 !default;
//$pagination-hover-border-color:     $gray-300 !default;
//
//$pagination-active-color:           $component-active-color !default;
//$pagination-active-bg:              $component-active-bg !default;
//$pagination-active-border-color:    $pagination-active-bg !default;
//
//$pagination-disabled-color:         $gray-600 !default;
//$pagination-disabled-bg:            $white !default;
//$pagination-disabled-border-color:  $gray-300 !default;

@import "~bootstrap/scss/variables";

// ngx-slider

$handleSize: 15px;
$barDimension: 5px;
$handlePointerSize: 0;
$handleBgColor: $blue;
$barFillColor: $hover-blue;
$labelFontSize: $small-font-size;
$barNormalColor: transparentize($dark, 0.9);
$sliderMarginWithLabel: 15px;

@import "~@angular-slider/ngx-slider/scss/ngx-slider";
