/* You can add global styles to this file, and also import other style files */

@import "variables";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";

@import "theme";

@import url('https://fonts.googleapis.com/css?family=Istok+Web|Source+Sans+Pro|Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html, body { height: 100%; }
